import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        &::selection {
            background: rgba(255, 255, 255, 0.25);
        }
    }

    html {
        font-size: 100%;
    }

    body {
        --color-bg: #141414;
        --color-bg-tinted: rgba(255, 255, 255, 0.08);
        --color-fg: white;
        --color-fg-subdued: rgba(255, 255, 255, 0.62);
        --color-fg-negative: firebrick;
        --color-fg-subdued-more: rgba(255, 255, 255, 0.55);
        --color-fg-subdued-non-text: rgba(255, 255, 255, 0.5);
        --color-fg-subdued-decorative: rgba(255, 255, 255, 0.04);
        --opacity-disabled: 0.35;

        --font-size-00: 0.75rem;
        --line-height-00: 1.5;
        
        --font-size-0: 1rem;
        --line-height-0: 1.5;
        
        --font-size-1: 1.25rem;
        --line-height-1: 1.4;
        
        --font-size-2: 1.5rem;
        --line-height-2: 1.3;
        
        --font-size-3: 1.75rem;
        --line-height-3: 1.2;
        
        --font-size-4: 2rem;
        --line-height-4: 1.1;

        --font-size-5: 3rem;
        --line-height-5: 1.0;

        --spacer-0000: 0.25rem;
        --spacer-000: 0.5rem;
        --spacer-00: 0.75rem;
        --spacer-0: 1rem;
        --spacer-1: 1.25rem;
        --spacer-2: 1.5rem;
        --spacer-3: 1.75rem;
        --spacer-4: 2rem;
        --spacer-5: 3rem;
        --spacer-6: 4rem;
        --spacer-7: 5rem;
        --spacer-8: 6rem;
        --spacer-9: 8rem;
        --spacer-10: 10rem;
        
        background: var(--color-bg);
        color: var(--color-fg);
        font-family: -apple-system, Helvetica, Arial, sans-serif;
        font-size: var(--font-size-0);
        line-height: var(--line-height-0);
        font-weight: 400;
        padding: 0 0 0 0;
        margin: 0 auto;

        @media (min-width: 768px) {
            --font-size-4: 3rem;
            --line-height-4: 1.0;

            --font-size-5: 5rem;
            --line-height-5: 1.0;
        }

        padding-block-start: var(--spacer-2);
        padding-block-end: var(--spacer-8);
        padding-inline: var(--spacer-2);

        @media (min-width: 768px) {
            padding-block-start: var(--spacer-5);
            padding-block-end: var(--spacer-9);
            padding-inline: var(--spacer-5);
        }
    }

    .type-size-00 {
        font-size: var(--font-size-00);
        line-height: var(--line-height-00);
    }

    .type-size-0 {
        font-size: var(--font-size-0);
        line-height: var(--line-height-00);
    }

    .type-size-1 {
        font-size: var(--font-size-1);
        line-height: var(--line-height-1);
    }

    .type-size-2 {
        font-size: var(--font-size-2);
        line-height: var(--line-height-2);
    }

    .type-size-3 {
        font-size: var(--font-size-3);
        line-height: var(--line-height-3);
    }

    .type-size-4 {
        font-size: var(--font-size-4);
        line-height: var(--line-height-4);
    }

    .type-size-5 {
        font-size: var(--font-size-5);
        line-height: var(--line-height-5);
    }

    .type-size-6 {
        font-size: var(--font-size-6);
        line-height: var(--line-height-6);
    }

    .color-fg-subdued {
        color: var(--color-fg-subdued);
    }

    .font-weight-bold {
        font-weight: bold;
    }

    .stack-0000 > * + * {
        margin-block-start: var(--spacer-0000);
    }
    .stack-000 > * + * {
        margin-block-start: var(--spacer-000);
    }
    .stack-00 > * + * {
        margin-block-start: var(--spacer-00);
    }
    .stack-0 > * + * {
        margin-block-start: var(--spacer-0);
    }
    .stack-1 > * + * {
        margin-block-start: var(--spacer-1);
    }
    .stack-2 > * + * {
        margin-block-start: var(--spacer-2);
    }
    .stack-3 > * + * {
        margin-block-start: var(--spacer-3);
    }
    .stack-4 > * + * {
        margin-block-start: var(--spacer-4);
    }
    .stack-5 > * + * {
        margin-block-start: var(--spacer-5);
    }
    .stack-6 > * + * {
        margin-block-start: var(--spacer-6);
    }
    .stack-7 > * + * {
        margin-block-start: var(--spacer-7);
    }
    .stack-8 > * + * {
        margin-block-start: var(--spacer-8);
    }
    .stack-9 > * + * {
        margin-block-start: var(--spacer-9);
    }
    .stack-10 > * + * {
        margin-block-start: var(--spacer-10);
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .flex-align-stretch {
        align-items: stretch;
    }

    .flex-align-center {
        align-items: center;
    }

    .flex-justify-center {
        justify-content: center;
    }

    .gap-0000   { gap: var(--spacer-0000); }
    .gap-000    { gap: var(--spacer-000); }
    .gap-00     { gap: var(--spacer-00); }
    .gap-0      { gap: var(--spacer-0); }
    .gap-1      { gap: var(--spacer-1); }
    .gap-2      { gap: var(--spacer-2); }
    .gap-3      { gap: var(--spacer-3); }
    .gap-4      { gap: var(--spacer-4); }
    .gap-5      { gap: var(--spacer-5); }
    .gap-6      { gap: var(--spacer-6); }
    .gap-7      { gap: var(--spacer-7); }
    .gap-8      { gap: var(--spacer-8); }
    .gap-9      { gap: var(--spacer-9); }
    .gap-10     { gap: var(--spacer-10); }

    button, 
    input,
    legend,
    fieldset, 
    a,
    p, h1, h2, h3, h4, h5, h6, 
    ul, ol, li {
        all: unset;
        box-sizing: border-box;
    }

    legend,
    fieldset, 
    p, 
    h1, h2, h3, h4, h5, h6, 
    ul, ol, li {
        display: block;
    }

    a {
        text-decoration: underline;
        transition: box-shadow 0.075s ease-out;
        border-radius: 2px;
        cursor: pointer;
        &:focus {
            outline: none; 
            box-shadow: 0 0 0 2px var(--color-fg);
            transition: box-shadow 0.1s ease-out; 
        }
    }

    .list-bulleted {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        list-style: disc outline none;
        padding-inline-start: 1rem;
    }

    .w-block {
        width: 100%;
    }

    .screen-reader-only {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
`

export default GlobalStyles;