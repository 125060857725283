
import { useState } from "react";
import styled from "styled-components";

const App = () => {

  const [expandProjects, setExpandProjects] = useState(false);
  const [expandExperience, setExpandExperience] = useState(false);

  // Calculate years of experience
  const dateFirstJob = new Date("07/01/2005");  
  const dateToday = new Date(Date.now()); 
  const yearsExperience = dateToday.getFullYear() - dateFirstJob.getFullYear();

  return (
    <Container>
      <header className="flex-row gap-2">
        <div>David Fusilier</div>
        <div className="color-fg-subdued">Product Designer</div>
        <div className="color-fg-subdued">New York, NY</div>
      </header> 
      <Section>
        <h1>
          <MobileDivider />
          About
        </h1>
        <div className="stack-4">
          <div className="stack-0">
            <p className="type-size-1">I'm a designer and engineer with over {yearsExperience} years of experience spanning product design, computational design, front-end development, accessibility, brand design, and design systems.</p>
            <p className="type-size-1">I have a proven track record of using first-principles thinking to design solutions that unite creatives and engineers and scale businesses.</p>
          </div>
          <div className="flex-row gap-2 color-fg-subdued">
            <li><a href="mailto:dfusil2@gmail.com">dfusil2@gmail.com</a></li>
            <li><a href="https://www.linkedin.com/in/dfusilier/">LinkedIn</a></li>
            <li><a href="https://twitter.com/davidfusilier">Twitter</a></li>
          </div>
        </div>
      </Section>
      <Section>
        <h1>
          <MobileDivider />
          Projects
        </h1>
        <List>
          <ListItem
            pretitle="2020–Present"
            title={<a href="https://spotify.design/article/can-i-get-an-encore-spotifys-design-system-three-years-on">Encore</a>}
            body="Encore is Spotify's multi-platform family of design systems. It supports over 350 applications across Web, iOS, and Android helping ~300 designers and writers and ~700 engineers ship cohesive experiences, faster, together."
          />
          <ListItem
            pretitle="2022"
            title={<a href="./color-shades">Color shades</a>}
            body="Calculate a color's shade and use it to quickly determine accessible color contrast, or generate a palette of related colors."
          />
          <ListItem
            pretitle="2021"
            title={<a href="./cradle/">Cradle</a>}
            body="Generative art based on non-intersecting paths that pass through each point in a grid exactly once. Refresh to generate a new path."
          />
          <ListItem
            pretitle="2016–2020"
            title="Cookbook"
            body="Grubhub's cross-platform design system. Cookbook unified Grubhub's product ecosystem, accelerated redesigns, acquisitions, and feature launches, and used A/B testing to demonstrate a material impact to the company's bottom line."
          />
          { expandProjects && (
            <>
            <ListItem
            pretitle="2018"
            title={<a href="https://github.com/dfusilier/type-director">Type director</a>}
            body="Generate responsive, modular, nuanced typographic systems from only a few key variables."
            />
            <ListItem
              pretitle="2018"
              title={<a href="https://github.com/dfusilier/space">Space</a>}
              body="Comprehensive set of Sass mixins and utility classes for organizing space."
            />
            </>
          )}
          { !expandProjects && (
            <ButtonLink onClick={() => setExpandProjects(true)}>More</ButtonLink>
          )}
          
        </List>
      </Section>

      <Section>
        <h1>
          <MobileDivider />
          Experience
        </h1>
        <List>
          <ListItem
            pretitle="2020–Present"
            title="Staff Product Designer, Spotify"
            body="Product design for Encore, Spotify's multi-platform family of design systems. "
          />
          <ListItem
            pretitle="2015–2020"
            title="Associate Principal Product Designer & Design Manager, Grubhub"
            body="Grubhub is how millions of people find food and order delivery. Led and managed product designers working on Grubhub's diner apps. Founded Cookbook, Grubhub's design system. We used data to demonstrate its value to leadership, form a dedicated design systems team, and transform Grubhub's product ecosystem."
          />
          <ListItem
            pretitle="2013–2015"
            title="Senior User Experience Designer, Self-employed"
            body="Product design and design system work for Macmillan and various ed-tech clients."
          />
          <ListItem
            pretitle="2011–2013"
            title="Senior User Experience Designer, Macmillan"
            body="Macmillan publishes some of the most widely-respected textbooks for the sciences and humanities. Worked with editorial staff and textbook authors to conduct user research, and to design and release a new flagship learning management system."
          />
          { expandExperience && (
            <>
            <ListItem
              pretitle="2011–2013"
              title="Information & User Experience Designer, Parsons Institute for Information Mapping"
            />
            <ListItem
              pretitle="2009–2011"
              title="Junior Draftsman, Darden Studio"
            />
            <ListItem
              pretitle="2008–2009"
              title="Intern, Scott & Nix"
            />
            <ListItem
              pretitle="2006–2007"
              title="Graphic Designer, oktoberproject"
            />
            <ListItem
              pretitle="2005–2006"
              title="Graphic Designer, The Daily Reveille"
            />
            </>
          )}
          { !expandExperience && (
            <ButtonLink onClick={() => setExpandExperience(true)}>More</ButtonLink>
          )}
        </List>
      </Section>
    </Container>
  );
}

export default App;

const Container = styled.div`
    max-width: 40rem;
    & > * + * {
      margin-block-start: 6rem;
    }
    @media (min-width: 768px) {
      & > * + * {
        margin-block-start: 8rem;
      }
    }
`;

const Section = styled.section`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  gap: 2rem;
  @media (min-width: 768px) {
    grid-template-rows: 1fr;
    grid-template-columns: 6rem 1fr;
  }
`;

const List = styled.ul.attrs(props => ({
  className: props.className ? + props.className + " stack-4" : "stack-4"
}))``;

const ListItem = ({ pretitle, title, subtitle, body }) => 
  <li className="stack-00">
    <div className="stack-0000">
      { pretitle && 
        <div className="color-fg-subdued">{pretitle}</div>
      }
      { title && 
        <div className="type-size-1">{title}</div>
      }
      { subtitle && 
        <div className="color-fg-subdued">{subtitle}</div>
      }
    </div>
    { body && 
      <div className="color-fg-subdued">{body}</div>
    }
  </li>;

const ButtonLink = styled.button`
    color: var(--color-fg-subdued);
    text-decoration: underline;
    cursor: pointer;
`;

const MobileDivider = styled.span`
    color: var(--color-fg-subdued);
    @media (min-width: 768px) {
      display: none;
    }

    &:after {
      content: "⎯ ";
    }
`;

